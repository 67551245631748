@font-face {
  font-family: 'Carried Away';
  src: url(../public/assets/fonts/Ballerina_Script.ttf);
  font-display: swap;
}

body {
  font-size: 16px;
  max-width: 1920px;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.08em;
}

h1, h2, h3,h4, h5, h6 {
  font-family: 'Carried Away', Arial, Helvetica, sans-serif;
  letter-spacing: 0;
  font-display: swap;
}

.container {
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}