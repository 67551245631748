@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  /* background-color: rgb(238, 245, 245); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}
